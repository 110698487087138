.list {
  padding: 0;
  list-style: none;
}

.list > li {
  padding: 12px;
}

.list > li:nth-child(odd) {
  background-color: #dfdfdf;
}

.list > li:nth-child(even) {
  background-color: #efefef;
}
