:root {
  --border: var(--border-color) 1px solid;
  --border-2: var(--border-color-2) 1px solid;
  --border-color: #dedede;
  --border-color-2: #98aec4;
  --blue-gray: #798591;
  --button-color-1: #ff7f66;
  --button-color-1-disabled: #ff7f66cc;
  --container-width: 1024px;
  --dark-color: #444;
  --error: red;
  --gray: #9b9b9b;
  --input-border: var(--input-border-color) 1px solid;
  --input-border-color: #98aec4;
  --font-family: 'Roboto', sans-serif;
  --link-color: #0000EE;
  --progress-background-color: #e6e6e6;
  --progress-color: #18ce30;
  --header-height: 75px;

  --primary-dark-purple: #0d0630;
  --primary-blue: #1884c6;
  --primary-dark-gray: #3f454b;

  --accent-green: #c5d86d;
  --accent-orange: #fa9149;
  --accent-red: #e81b22;

  --secondary-blue-white: #e0eef6;
  --secondary-black: #000;
  --secondary-white: #fff;
  --secondary-light-gray: #f9f9f9;
  --secondary-silver: #838383;
}
