.responsiveTable {
  overflow-x: auto;
}

.table {
  border-collapse: collapse;
  margin: 16px 0;
  width: 100%;
}

.table td, .table th {
  border: 1px solid #cbcbcb;
  padding: 12px;
  text-align: left;
}

.table td.noPadding {
  padding: 0;
}

.table td:not(:last-child), .table th {
  white-space: nowrap;
}

.table > tr:nth-child(odd) {
  background-color: #dfdfdf;
}

.table > tr:nth-child(even) {
  background-color: #efefef;
}

@media (hover: hover) {
  .table tr:not(:first-child):hover {
    border: 1px solid var(--dark-color);
    background-color: #fff;
    cursor: pointer;
  }
}

.tableLink {
  padding: 12px;
}

.deleteableRow button {
  display: none;
}

.deleteableRow > td{
  position: relative;
}

.fullWidth {
  width: 100%;
}

@media (hover: hover) {
  .deleteableRow:hover button {
    background-color: var(--error);
    border: 0;
    bottom: 0;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 18px;
    /* height: 100%; */
    padding: 0 14px;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    z-index: 1;
  }
}


