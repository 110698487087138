@import './variables.css';

html, body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: #fff;
  font-weight: 400;
  color: var(--primary-dark-gray);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

html, body, button, input, select, textarea {
  font-family: var(--font-family);
}

#app {
  height: 100%;
}
