.form {
  margin: auto;
  width: 375px;
}

.formFull {
  margin: auto;
  position: relative;
  width: 100%;
}

.formFull > button + button {
  margin-left: 20px;
}

@media only screen and (max-width: 500px) {
  .formFull > button {
    display: block;
    margin: 10px 0;
    width: 100%;
  }

  .formFull > button + button {
    margin-left: 0;
  }
}

.formGroup {
  margin: 20px 0;
}

.formGroup label {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}

.helpLink {
  font-size: 12px;
  margin-left: 5px;
  text-decoration: none;
}

.formGroup input,
.formGroup select,
.formGroup textarea,
.formHorizontal input {
  background: white;
  border: var(--input-border);
  border-radius: 3px;
  display: block;
  font-size: 18px;
  margin-top: 10px;
  padding: 7px 10px;
  width: 300px;
}

.formGroup input:disabled,
.formGroup select:disabled,
.formGroup textarea:disabled,
.formHorizontal input:disabled {
  background: lightgray;
  cursor: not-allowed;
}

.required > span:after {
  content: " *";
  color: var(--primary-blue);
}
