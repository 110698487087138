.header {
  background: var(--primary-dark-gray);
  display:flex;
  font-size: 18px;
  height: var(--header-height);
  left: 0;
  padding: 0;
  top: 0;
  width: 100%;
  z-index: 50;
}

.header nav {
  font-size: 100%;
}

.header nav a {
	display: inline-block;
  height: var(--header-height);
	line-height: 75px;
	padding: 0 15px;
	min-width: 50px;
	text-align: center;
	background: rgba(255,255,255,0);
	text-decoration: none;
	color: #FFF;
	will-change: background-color;
}

.header nav a:hover,
.header nav a:active {
	background: rgba(0,0,0,0.2);
}

.header nav a.active {
  background: var(--primary-blue);
  font-weight: bold;
  text-decoration: underline;
}

.logo {
  height: 50px;
  margin: auto 30px auto 39px;
  width: 81px;
}

.user-menu {
  margin-left: 30px;
}

.right-buttons {
  display: flex;
  align-items: center;
  margin: 0 20px 0 auto;
}
