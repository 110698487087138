.primary {
  background-color: var(--button-color-1);
  border: 2px solid transparent;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
}

.blue {
  background-color: var(--primary-blue);
  border: 0;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 23px 9px 24px;
  text-align: center;
  text-decoration: none;
}

.blue:disabled {
  color: #9b9b99;
  cursor: not-allowed;
  background-color: var(--secondary-light-gray);
}

@media (hover: hover) {
  .primary:hover {
    background-color: #fff;
    border: 2px solid var(--button-color-1);
    color: var(--button-color-1);
  }
  .primaryInverse:hover {
    background-color: var(--button-color-1);
    border: 2px solid transparent;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    padding: 10px 20px;
    text-decoration: none;
  }
}

.primaryInverse {
  background-color: transparent;
  border: 2px solid var(--button-color-1);
  color: var(--button-color-1);
  cursor: pointer;
  font-size: 18px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
}

.destroy {
  background-color: var(--error);
  border: 2px solid transparent;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  float: right;
  padding: 10px 20px;
  text-decoration: none;
}

@media (hover: hover) {
  .destroy:hover {
    background-color: transparent;
    border: 2px solid var(--error);
    color: var(--error);
  }
}

@media only screen and (max-width: 500px) {
  .destroy {
    float: none;
  }

  .primary, .primaryInverse {
    display: block;
    margin: 10px 0;
    width: 100%;
  }
}

.link {
  background: transparent;
  border: none;
  color: var(--link-color);
  cursor: pointer;
  padding: 10px;
  display: inline;
  font-family: inherit;
  font-style: inherit;
  font-size: 16px;
}

.link:hover {
  text-decoration: underline;
}
